.ItemView {
  color: var(--primary-text-color); // Put here so it's affected by dark mode class

  &__none {
    padding: var(--spacing-xxxl) var(--spacing-xl);
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
  }
}

.ProductDetails {
  display: flex;
  margin: var(--spacing-large);
  border: 1px solid var(--layout-border-color);
  padding: var(--spacing-medium);
  border-radius: var(--border-radius-medium);

  &__thumbnail {
    width: 200px;
    transition: width var(--expand-animation-timing) 0.3s;
    margin-right: var(--spacing-medium);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      width: 160px;
    }

    &__image {
      max-width: 100%;
      max-height: 160px;
      object-fit: contain;
      border-radius: var(--border-radius-small);
    }
  }

  &__details {
    font-size: 14px;
    line-height: 22px;
    min-width: 0;
    flex-grow: 1;

    &__shortened {
      max-width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }

    &__name {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      -webkit-line-clamp: 2;
    }

    &__pricingAndAvailability {
      margin-top: var(--spacing-small);
      display: flex;
      width: 100%;
      font-weight: 500;
    }

    &__ratingStar {
      color: var(--color-explosive);
      margin-left: var(--spacing-xs);

      &--active {
        color: var(--color-working_orange);
      }
    }

    &__availability {
      margin: 0 var(--spacing-large);
      flex-grow: 1;
      flex-shrink: 0;

      &--positive {
        color: var(--positive-color);
      }

      &--negative {
        color: var(--negative-color);

      }
    }

    &__link {
      min-width: 0;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }
}

.Loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__holder {
    width: 48px;
    height: 48px;
    color: var(--icon-color);
  }
}