@keyframes slide-in-from-top {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.Wrapper {
  position: fixed;
  pointer-events: none;
  display: flex;
  top: 0px;
  will-change: transform;
  transition: transform var(--expand-animation-timing) 0.3s;
  transition-delay: 3s;

  &--left {
    width: 100px;
    height: 100%;
    align-items: center;
    transform: translateX(-100%);

    html:hover & {
      transform: translateX(0);
    }
  }

  &--top {
    width: 100%;
    height: 100px;
    justify-content: center;
    transform: translateY(-100%);
    animation: slide-in-from-top 0.3s var(--expand-animation-timing) 1;

    &--visible {
      html:hover & {
        transform: translateY(0);
      }
    }
  }

  html:hover & {
    transition-delay: 0s;
  }
}

.Tools {
  display: flex;
  background-color: var(--dialog-background-color);
  pointer-events: all;
  box-shadow: var(--box-shadow-medium);
  border-radius: var(--border-radius-medium);

  &--left {
    margin-left: var(--spacing-medium);
    flex-direction: column;
  }

  &--top {
    margin-top: var(--spacing-medium);
    flex-direction: row;
    height: fit-content;
  }

  &__divider {
    margin: var(--spacing-small);
    height: 1px;
    background-color: var(--color-ui_grey);

    &--vertical {
      height: 22px;
      width: 1px;
    }
  }

  &__tool {
    margin: var(--spacing-small);
  }

  &__iconWrapper {
    display: flex;
  }

  &__icon {
    cursor: pointer;
    padding: var(--spacing-small);
    border-radius: var(--border-radius-small);

    &:hover:not(&--active) {
      transition: background-color 0.15s ease-in, color 0.15s ease-in;
      background-color: var(--primary-background-hover-color);
    }

    &--active {
      transition: background-color 0.15s ease-in, color 0.15s ease-in;
      color: var(--primary-color);
      background-color: var(--primary-selected-color);
    }
  }
}

.InnerOptions {
  opacity: 0;
  transition: opacity var(--expand-animation-timing) 0.3s;
  transition-delay: 3s;
  box-shadow: var(--box-shadow-medium);
  color: var(--primary-text-color);

  html:hover & {
    opacity: 1;
    transition-delay: 0s;
  }

  &--horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.Color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--dialog-background-color);
  margin: var(--spacing-small);
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.15s ease-in;
  padding: 0;
  background-color: currentColor;

  &--primaryTextColor {
    color: var(--primary-text-color);
  }

  &:hover {
    transform: scale(1.2);
    box-shadow: var(--box-shadow-small);
  }
}

.WidthIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__dot {
    background-color: currentcolor;
    border-radius: 50%;
  }
}

.InfoBox {
  max-width: 300px;
  padding: var(--spacing-medium);
  font-size: 14px;

  &__message {
    margin-bottom: var(--spacing-medium);
  }

  &__iconRow {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-medium);

    &__icon {
      flex-shrink: 0;
      margin-right: var(--spacing-medium);
    }
  }
}