@keyframes slideInFromLeft {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__holder {
    width: 48px;
    height: 48px;
    color: var(--icon-color);

    animation: slideInFromLeft var(--expand-animation-timing) 0.3s;
  }
}

.VisionBoard {
  overflow: hidden;
  color: var(--primary-text-color); // Put here so it's affected by dark mode class
}