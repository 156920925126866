.DashboardWidget {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__urlInputHolder {
    width: 90%;
    max-width: 600px;

    &__icon {
      // Handle sizing here so that hover works for entire button
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__loader {
      display: inherit;
      width: 16px;
      height: 16px;
      color: var(--icon-color);
    }
  }
}

.ProductDetails {
  display: flex;

  &__thumbnail {
    max-width: 200px;
    max-height: 160px;
    object-fit: contain;
    margin: var(--spacing-small);

  }

  &__details {
    max-width: 200px;
    font-size: 14px;
    line-height: 22px;
    margin: var(--spacing-small);

    &__shortened {
      max-width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }

    &__name {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      -webkit-line-clamp: 2;
    }

    &__pricingAndAvailability {
      margin-top: var(--spacing-small);
      display: flex;
      width: 100%;
      font-weight: 500;
    }



    &__ratingStar {
      color: var(--color-explosive);
      margin-left: var(--spacing-xs);

      &--active {
        color: var(--color-working_orange);
      }
    }

    &__availability {
      margin-left: var(--spacing-large);

      &--positive {
        color: var(--positive-color);
      }

      &--negative {
        color: var(--negative-color);

      }
    }
  }

}